'use strict';

var addProgramModal = {
    handleEvents: function () {
        // Create new program modal, reload programs afterward
        $('body').on('submit', '.program-add-form', function (e) {
            e.preventDefault();
            var $form = $(this);
            var url = $form.attr('action');
            var serializedData = $form.serialize();
            var $alert = $form.find('.alert');
            var $modal = $form.closest('.modal');
            var $list = $('.programs-list');

            if ($list.length === 0) {
                $list = $('.fp-programs-list');
                serializedData += '&responseTemplate=1'; 
            }

            $modal.spinner('small').start();
            $.ajax({
                type: 'POST',
                url: url,
                data: serializedData,
                success: function(response) {
                    if (response && response.success) {
                        $list.html(response.html);
                        $modal.spinner().stop();
                        $modal.modal('hide');

                        // Clear form inputs
                        $form.find('input, textarea').val('');
                    } else {
                        $alert.html(response.message).show();
                    }
                },
                error: function() {
                    $modal.spinner().stop();
                }
            });
        });
    }
};

module.exports = addProgramModal;