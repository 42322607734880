'use strict';

var programQuickView = {
    handleEvents: function () {
        var $doc = $(document);

        $doc.on('change', '.employee-list-quickview .employee', function (e) {
            e.preventDefault();
            var $employee = $(this);
            var data = $employee.data();

            programQuickView.toggleATCButton();
        });

        $doc.on('click', '.add-employee-quickview', function (e) {
            e.preventDefault();
            $('.add-employee-quickview-form').slideToggle();
        });

        $doc.on('submit', '.add-employee-form', function (e) {
            e.preventDefault();
            var $form = $(this);
            var url = $form.attr('action');
            var data = $form.serialize();
            var $employeeList = $('.employee-list-quickview .employee-list-col');
            var $employeeListEmpty = $('.employee-list-row-empty');
            var $addEmployeeAlert = $('.quickview-addemployee-alert');
            $addEmployeeAlert.hide();
            $.ajax({
                url: url,
                data: data,
                method: 'POST',
                success: function (data) {

                    if(data.success) {
                        $employeeListEmpty.hide();
                        var $data = $(data.html);
                        $employeeList.html($data.find('.employee-list-col').html());
                        $form[0].reset();
                    } else {
                        $addEmployeeAlert.text(data.message).fadeIn('fast');
                    }
                },
                error: function (err) {
                    console.error(err);
                    $addEmployeeAlert.text(data.message).fadeIn('fast');
                }
            });
        });

        $doc.on('click', '.b2b-qv-headers .toggle', function (e) {
            e.preventDefault();
            var $this = $(this);
            var target = $this.data('qv-target');
            var $b2bQvHeader = $('.b2b-qv-headers .toggle');
            var $b2bQvBody = $('.b2b-qv-body > div');
            // add d-none to b2bQVBody
            $b2bQvBody.addClass('d-none');
            // remove active from b2bQvHeader
            $b2bQvHeader.removeClass('active');
            //remove d-none from target
            $('.' + target).removeClass('d-none');
            // add active to current
            $this.addClass('active');
        });

        // remove on click event from .b2b-qv-atc and then re-add it
        $doc.on('click', '.b2b-qv-atc', function (e) {
            e.preventDefault();
            var $this = $(this);
            // get data-b2b-qv-atc-url url
            var url = $this.data('b2b-qv-atc-url');

            var $b2bQvContainer = $('.b2b-qv-container');

            var $qvAddtocartAlert = $('.quickview-addtocart-alert');
            $qvAddtocartAlert.hide();

            var products = programQuickView.getProducts();
            // get the number of keys in products
            if (Object.keys(products).length > 0) {
                $b2bQvContainer.spinner().start();
                 $.ajax({
                    type: 'POST',
                    url: url,
                    data: {
                        products: JSON.stringify(products)
                    },
                    success: function(response) {
                        if (response.success) {
                            // do something
                            $('body').trigger('product:afterAddToCart', response);
                            $('.minicart').trigger('count:update', response);
                        } else {
                            $qvAddtocartAlert.text(response.message).show('fast');
                        }
                        $b2bQvContainer.spinner().stop();
                    },
                    error: function(error, ajaxOptions, thrownError) {
                        // Don't stop spinner or print error if ajax call purposely aborted
                        if (thrownError === 'abort') {
                            return;
                        }
                        console.error(error);
                        $b2bQvContainer.spinner().stop();
                    }
                });
            }
        });

        $('body').on('product:afterAttributeSelect', function (e, response) {
                $('.add-employee-pid').val(response.data.product.id);
        });

        $doc.on('change', '.b2b-qv-select-body .b2b-qv-quantity', function (e) {
            e.preventDefault();
            var $this = $(this);
            // get slected option and its data-url
            var selectedOption = $this.find('option:selected');
            var selectedOptionUrl = selectedOption.attr('data-url');
            programQuickView.toggleATCButton();
            programQuickView.attributeSelect(selectedOptionUrl, $this.closest('.employee-list-row'));
        });

        $doc.on('change', '.b2b-qv-select-body .b2b-qv-attribute', function (e) {
            e.preventDefault();
            var $this = $(this);
            programQuickView.toggleATCButton();
            programQuickView.attributeSelect(e.currentTarget.value, $this.closest('.employee-list-row'));
        });
    },
    toggleATCButton: function() {
        var products = programQuickView.getProducts();

        if(typeof products !== 'object') {
            return;
        }

        if(Object.keys(products).length > 0) {
            $('.b2b-qv-atc').removeAttr('disabled');
        } else {
            $('.b2b-qv-atc').attr('disabled', 'disabled');
        }
    },
    getProducts: function() {
        // interate through each .employee-list-row and check if the .employeeProduct is ready and the .employee checkbox is checked. if so then add to cart
        var $employeeList = $('.employee-list-row');
        if (!$employeeList.length) {
            return;
        }
        var products = {};
        $employeeList.each(function() {
            var $employee = $(this);
            var $employeeProduct = $employee.find('.employeeProduct');
            var $employeeCheckbox = $employee.find('.employee');
            var $qty = $employee.find('.b2b-qv-quantity');

            if ($employeeCheckbox.is(':checked')) {
                // is every product attributes selected and the checkbox checked
                var $b2bQvAttributes = $employee.find('.b2b-qv-attributes select');
                var allSelected = true;
                $b2bQvAttributes.each(function() {
                    var $this = $(this);
                    if ($this.val() === '') {
                        allSelected = false;
                    }
                });

                if (!allSelected) {
                    products = {};
                    return false;
                }

                if ($employeeProduct.attr('data-isready') === 'true' && allSelected) {
                    var employeeID = $employeeCheckbox.val();
                    var itemUUID = $employeeProduct.attr('data-itemuuid');
                    var qty = $qty.length ? $qty.val() : 1;
                    var pid = $employeeProduct.val();
                    products[pid] = {
                        id: pid,
                        employeeID: employeeID,
                        itemUUID: itemUUID,
                        matched: true,
                        quantity: qty
                    };
                } 
            }
        });
        return products;
    },
    attributeSelect: function(selectedValueUrl, $employeeRowContainer) {
        if(!selectedValueUrl) {
            return;
        }
        var methods = this;

        if (selectedValueUrl) {
            $('body').trigger('product:beforeB2BAttributeSelect', {
                url: selectedValueUrl,
                container: $employeeRowContainer
            });

            // append the programid and employee id to the url
            var programID = $employeeRowContainer.attr('data-program-id');
            var employeeID = $employeeRowContainer.attr('data-employee-id');
            selectedValueUrl += '&programid=' + programID + '&employeeid=' + employeeID;

            $.ajax({
                url: selectedValueUrl,
                method: 'GET',
                success: function (data) {
                    programQuickView.handleVariantResponse(data, $employeeRowContainer);
                    programQuickView.updateQuantities(data.product.quantities, $employeeRowContainer);

                    $('body').trigger('product:afterB2BAttributeSelect', {
                        data: data,
                        container: $employeeRowContainer
                    });
                },
                error: function (err) {
                    console.error(err);
                }
            });
        }
    },
    updateQuantities: function(quantities, $employeeRowContainer) {
        if(!quantities) {
            return;
        }
        var $b2bQvQuantity = $employeeRowContainer.find('.b2b-qv-quantity');
        if ($b2bQvQuantity.length) {
            var optionsHtml = quantities.map(function (quantity) {
                var selected = quantity.selected ? ' selected ' : '';
                return '<option value="' + quantity.value + '"  data-url="' + quantity.url + '"' +
                    selected + '>' + quantity.value + '</option>';
            }).join('');
            $b2bQvQuantity.html(optionsHtml);
        }
    },
    handleVariantResponse: function(response, $employeeRowContainer) {
        if (!response) {
            return;
        }
        // Update pricing *
        // finde .employeeProduct in $employeeRowContainer and replace with response.product.id
        if ('product' in response){
            var $employeeProduct = $employeeRowContainer.find('.employeeProduct');
            $employeeProduct.val(response.product.id).attr('data-isready', response.product.readyToOrder);

            // update availability
            var $b2bQVStatus = $employeeRowContainer.find('.b2b-qv-status');
            if (!response.product.availability) {
                $b2bQVStatus.text(response.product.availability.messages).show('fast');
            } else {
                $b2bQVStatus.hide('fast');
            }

            // Update Variant Attributes
            $employeeRowContainer.find('.b2b-qv-attributes .b2b-qv-attribute').each(function(select) {
                var $select = $(this);
                // get data-attr value
                var attr = $select.data('attr');
                // find the div with the same data-attr value in the response and replace the html
                var $responseAttr = $(response.b2bVariantAttributeHTML).closest('div[data-attr="' + attr + '"]');
                if($responseAttr.length) {
                    // replace the html
                    $select.html($responseAttr.html());
                }
            });
        } else {
            $employeeProduct.val(response.product.id).attr('data-isready', false);
        }
        programQuickView.toggleATCButton();
    }
};

module.exports = programQuickView;