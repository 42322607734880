'use strict';

const base = require('integrations/product/base');
const scrollAnimate = require('base/components/scrollAnimate');
/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 */
 function processSwatchValues(attr, $productContainer, msgs) {
    if (attr.attributeId == 'color') {
        $productContainer.find('.color-display-value').text(attr.displayValue || '');
    };

    if (attr.attributeId == 'size') {
        //
        $productContainer.find('[data-attr="' + attr.attributeId + '"] .non-color-display-value').text(attr.displayValue || '');
        // $productContainer.find('.non-color-display-value').text(attr.displayValue || '');
    };

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' + attrValue.value + '"]');

        if (!$attrValue.length) return;

        var $swatchButton = $attrValue.prop('tagName').toLowerCase() === 'button'
            ? $attrValue
            : $attrValue.parent('button');

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
            $attrValue.attr('selected', 'selected');
        } else {
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
            $attrValue.removeAttr('selected');
        }

        if (attrValue.url) {
            $swatchButton.attr('data-url', attrValue.url);
        } else {
            $swatchButton.removeAttr('data-url');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable available unavailable');

        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
        $attrValue.addClass(attrValue.available ? 'available' : 'unavailable');

        $attrValue.attr('value', attrValue.url).removeAttr('disabled');
        if (!attrValue.selectable) {
            $attrValue.attr('disabled', true);
        }
    });
}

/**
 * Changed from Autobahn base to update the selected option (cosmetic bug only). When one option is
 * available and the customer deselects it in a select dropdown, the server doesn't deselect the attribute,
 * which causes the Add to Cart button to be enabled without all attributes being visibly selected.
 *
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processNonSwatchValues(attr, $productContainer, msgs) {
    var $attr = '.custom-select[data-attr="' + attr.id + '"]';
    var $defaultOption = $productContainer.find($attr + '.select-' + attr.id + ' option:first-child');
    $defaultOption.attr('value', attr.resetUrl).attr('disabled', true);

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find($attr + ' [data-attr-value="' + attrValue.value + '"]');
        $attrValue.attr('value', attrValue.url).removeAttr('disabled');
        var currentSelectedOption = $productContainer.find($attr + '.select-' + attr.id + ' option:selected').eq(0);

        if (!attrValue.selectable || !attrValue.available) {
            if (!attrValue.selectable) {
                $attrValue.attr('disabled', true);
            }
            //check if selected value is now unavailable, if so select the default option
            if (currentSelectedOption.data('attr-value') == attrValue.value) {
                $attrValue.removeAttr('selected');
                $($attr).prop('selectedIndex', 0);
            }
            // append a msg to option to tell user its not available with selected options
            $attrValue.html(attrValue.displayValue + msgs.unavailableMsg);
        } else {
            $attrValue.html(attrValue.displayValue);
            if (currentSelectedOption.text() == attrValue.displayValue) {
                $(currentSelectedOption).attr('selected', 'selected');
                $($attr).prop('selectedIndex', $(currentSelectedOption).index());
            }
        }

        if (attrValue.selected) {
            $attrValue.prop('selected', true).attr('selected', true);
        } else if ($attrValue.prop('selected')) {
            $attrValue.prop('selected', false).attr('selected', false);
        }
    });
}

function nonColorAttribute() {
    var scope = this;

    $(document).on('click', 'button.swatch', function (e) {
        e.preventDefault();

        if (scope.methods.checkForClickableAttribute($(this))) {
            return;
        }
        var $productContainer = $(this).closest('.set-item');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.product-detail');
        }

        scope.methods.attributeSelect($(this).attr('data-url'), $productContainer);

        // find what type of attribute it is
        // var attributeID = $(this).data("attr");
        $(this).parents(".non-color-attribute-swatches").find(".non-color-display-value").text($(this).find('.swatch-value').data('display-value'));

        // $productContainer.find('.non-color-display-value').text($(this).find('.swatch-value').data('display-value'));
    });
}

function addBonusProductsToCart() {
    $(document).on('click', '.add-bonus-products', function () {
        var $readyToOrderBonusProducts = $('.choose-bonus-product-dialog .selected-pid');
        var queryString = '?pids=';
        var url = $('.choose-bonus-product-dialog').data('addtocarturl');
        var pidsObject = {
            bonusProducts: []
        };

        $.each($readyToOrderBonusProducts, function () {
            var qtyOption =
                parseInt($(this)
                    .data('qty'), 10);

            var option = null;
            if (qtyOption > 0) {
                if ($(this).data('optionid') && $(this).data('option-selected-value')) {
                    option = {};
                    option.optionId = $(this).data('optionid');
                    option.productId = $(this).data('pid');
                    option.selectedValueId = $(this).data('option-selected-value');
                }
                pidsObject.bonusProducts.push({
                    pid: $(this).data('pid'),
                    qty: qtyOption,
                    options: [option]
                });
                pidsObject.totalQty = parseInt($('.pre-cart-products').html(), 10);
            }
        });
        queryString += JSON.stringify(pidsObject);
        queryString = queryString + '&uuid=' + $('.choose-bonus-product-dialog').data('uuid');
        queryString = queryString + '&pliuuid=' + $('.choose-bonus-product-dialog').data('pliuuid');
        $.spinner().start();
        $.ajax({
            url: url + queryString,
            method: 'POST',
            success: function (data) {
                $.spinner().stop();
                if (data.error) {
                    $('#chooseBonusProductModal').modal('hide');
                    if ($('.add-to-cart-messages').length === 0) {
                        $('body').append('<div class="add-to-cart-messages"></div>');
                    }
                    $('.add-to-cart-messages').append(
                        '<div class="alert alert-danger add-to-basket-alert text-center"'
                        + ' role="alert">'
                        + data.errorMessage + '</div>'
                    );
                    setTimeout(function () {
                        $('.add-to-basket-alert').remove();
                    }, 3000);
                } else {
                    $('.configure-bonus-product-attributes').html(data);
                    $('.bonus-products-step2').removeClass('hidden-xl-down');
                    $('#chooseBonusProductModal').modal('hide');

                    if ($('.add-to-cart-messages').length === 0) {
                        $('body').append('<div class="add-to-cart-messages"></div>');
                    }
                    $('.minicart-quantity').attr('data-quantity', data.totalQty).html(data.totalQty);
                    $('.add-to-cart-messages').append(
                        '<div class="alert alert-success add-to-basket-alert text-center"'
                        + ' role="alert">'
                        + data.msgSuccess + '</div>'
                    );
                    $('body').trigger('product:afterAddBonusProduct');
                    setTimeout(function () {
                        $('.add-to-basket-alert').remove();
                        if ($('.cart-page').length) {
                            location.reload();
                        }
                    }, 1500);
                }
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
}

function sizeChart() {
    $('body').on('click', '.size-chart-launcher', event => {
        event.preventDefault();

        var productId = $(event.target).closest('.product-detail').find('.product-id').html();
        var $sizeChartModal = $('.size-chart-modal[data-product="' + productId + '"]');

        //if the sizechart is from a quickview append after all the modal-backdrops
        if ($(event.target).parents('.product-quickview').length) {
            var $sizeChartContainer = $(event.target).closest('.size-chart');
            $sizeChartModal.appendTo('body');
            $sizeChartModal.on('hide.bs.modal', event => {
                $sizeChartModal.appendTo($sizeChartContainer);
            });

            // Base AB adds a max-height to the quickview modal, which is also applied to the size chart modal
            // Reset the max-height when launching the size chart modal
            $sizeChartModal.find('.modal-body').css('max-height', '');

            // Autobahn sets the modal title when the quickview is launched, and because the size chart
            // is contained in the quickview modal the title is also applied to the size chart.
            // Remove the title from the size chart modal
            $sizeChartModal.find('.modal-title').remove();
        }


        $sizeChartModal.modal('show');
    });

    $('body').on('click', '.size-chart-referrer', event => {
        $('.size-chart-launcher:visible').first().click();
    });

    // custom close event to allow closing only this modal inside quickview modals
    $('body').on('click', '.size-chart-modal .close', event => {
        var productId = $(event.target).closest('.size-chart-modal').data('product');
        $('.size-chart-modal[data-product="' + productId + '"]').modal('hide');
    });
}

function clearMissingVariationAttributeMessages() {
    $('.product-detail-attributes .watch-missing-attr').each((index, attr) => {
        var $attr = $(attr);
        var $dropdown = $attr.find('select');
        var hasDropdownSelection = $dropdown.find('option:selected:not(.empty-option)').length > 0 && $dropdown.val();
        if ($attr.find('.selected').length > 0 || hasDropdownSelection) {
            // Attribute selected, hide error message
            $attr.find('.missing-attr-error').addClass('d-none');
        }
    });
}

function displayMissingVariationAttributeSelections() {
    $('.product-detail-attributes .watch-missing-attr').each((index, attr) => {
        var $attr = $(attr);
        var $dropdown = $attr.find('select');
        var missingDropdownSelection = $dropdown.find('option:selected:not(.empty-option)').length === 0 || $dropdown.val() === null;
        if ($attr.find('.selected').length === 0 && missingDropdownSelection) {
            // No selected attribute, show attribute error message
            $attr.find('.missing-attr-error').removeClass('d-none');
        }
    });
}

function disabledAddToCartScrollToTop() {
    // Attach listener to the Add to Cart button container since disabled buttons don't emit a click event
    $('.add-to-cart-container').click(function () {
        if ($(this).find('.add-to-cart').is(':disabled')) {
            displayMissingVariationAttributeSelections();
            scrollAnimate();
        }
    });

    // Attach listener to clear missing variation attribute error messages, after attribute selection
    $('body').on('product:afterAttributeSelect', function () {
        clearMissingVariationAttributeMessages();
    })
}

function handleSpinner() {
    $('body').on('product:beforeAddToCart', function (e, data) {
        if (!$(data).hasClass('qv-product-edit-add')) { // Quickview handles spinner differently in quickView.js
            $.spinner().start();
        }
    });

    $('body').on('product:afterAddToCart', function (e, data) {
        $.spinner().stop();
    });
}

function enhancedAddToCartMessaging() {
    var $addedToCartModal = $('#addedToCartModal');

    $addedToCartModal.on('hidden.bs.modal', function (e) {
        $('.fixed-header').removeClass('z-index-over-modal-background');
    });

    $('body').on('product:afterAddToCart', function (e, data) {
        if (data && data.error === false) {
            var addedUUID = data.pliUUID;
            var items = data.cart && data.cart.items;
            var viewCartButton = data.cart.viewCartText;

            var addedProduct = null;

            if (items) {
                for (var i = 0; i < items.length; i++) {
                    var item = items[i];
                    if (item.UUID === addedUUID) {
                        addedProduct = item;
                        break;
                    }
                }

                if (addedProduct) {
                    var modalBodyHtml = '';

                    var isCustomizerProduct = addedProduct.hasOwnProperty('customizer') ? addedProduct.customizer.isCustomized : false;
                    // Create the product image
                    var imageUrl = isCustomizerProduct ? addedProduct.customizer.recipePreview : addedProduct.images.small[0].url;
                    var imageAlt = addedProduct.images.small[0].alt;
                    var productImageContainer = '<div class="col-4 image-col"><div class="item-image-container"><img class="item-image" src="' + imageUrl + '" alt="' + imageAlt + '" /></div></div>';

                    // Create the right side details of the card
                    var productName = '<div class="item-name">' + (addedProduct.shortName && data.showShortName ? addedProduct.shortName : addedProduct.productName) + (isCustomizerProduct? ' ' + data.customizerAppendLabel : '') + '</div>';
                    var productPrice = '<div class="attribute-pair price"><span class="attribute-value item-total"><span class="pricing line-item-total-price-amount">' + addedProduct.price.sales.formatted + '</span></span></div>';

                    var attributeHtml = '';
                    addedProduct.variationAttributes.forEach(function(attribute) {
                        attributeHtml += '<div class="attribute-pair">';
                        attributeHtml += '<span class="attribute-key">' + attribute.displayName + ':</span>';
                        attributeHtml += '<span class="attribute-value">' + attribute.displayValue + '</span>';
                        attributeHtml += '</div>';
                    });
                    attributeHtml = '<div class="pb-1 item-attributes">' + attributeHtml + '</div>';

                    var productDetailsContainer = '<div class="col-8">' + productName + attributeHtml + productPrice + '</div>';

                    // Create the modal body
                    modalBodyHtml = '<div class="minicart"><div class="row line-item">' + productImageContainer + productDetailsContainer + '</div></div>';

                    // Set the position of the modal to the height of the header
                    var headerHeight = $('#top-header').outerHeight();
                    $addedToCartModal.find('.modal-dialog').css('top', headerHeight + 'px');

                    // Add the modal body that we generated above
                    $addedToCartModal.find('.modal-body').html(modalBodyHtml);

                    // Update view cart button with number of items
                    $addedToCartModal.find('.view-cart-button').text(viewCartButton);

                    // Add class so the header displays over the background
                    $('.fixed-header').addClass('z-index-over-modal-background');

                    // Now, remove the old messaging
                    $('.add-to-cart-messages').remove();

                    // Finally show the modal
                    $addedToCartModal.modal('show');

                    // Set a time out to hide the modal after 5 seconds
                    setTimeout(function () {
                        $addedToCartModal.modal('hide');
                    }, 5000);
                } else {
                    console.error('Cart Message: Unable to find recently added product:', data);
                }
            } else {
                console.error('Cart Message: Missing "items".', data);
            }
        } else {
            console.error('Cart Message: Missing "data" or Error returned.', data);
        }
    });
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
 function handleVariantResponse(response, $productContainer) {
    var isChoiceOfBonusProducts = $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVariant;
    if (response.product.variationAttributes) {
        this.updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
        isVariant = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVariant) {
            $productContainer.parent('.bonus-product-item').data('pid', response.product.id);
            $productContainer.parent('.bonus-product-item').data('ready-to-order', response.product.readyToOrder);
        }
    }

    // Update primary images
    var primaryImages = response.product.images;
    var $oldWishlistIcon = $productContainer.find('div.slide a.wishlist');
    this.createSlider(primaryImages, $productContainer);

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        var $priceSelector = $('.prices .price', $productContainer).length
            ? $('.prices .price', $productContainer)
            : $('.prices .price');
        $priceSelector.replaceWith(response.product.price.html);
    }

    // Update promotions
    $productContainer.find('.promotions').empty().html(response.product.promotionsHtml);

    this.updateAvailabilityProcess(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product, $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product, $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty().html(this.getAttributesHtml(response.product.attributes));

    // Update wishlist
    if ($oldWishlistIcon && $oldWishlistIcon.length) {
        var $newWishlistIcon = $($oldWishlistIcon[0]);
        $newWishlistIcon.attr('data-wishlistpid', response.product.wishlistpid);

        //Make heart icon accurate
        var wishlist = require('../wishlist/wishlist.js');
        wishlist.updateWishlistLinkData($newWishlistIcon);

        var $newSliderMainImages = $productContainer.find('div.primary-images-main div.slide img');
        $newSliderMainImages.each((_i, newImage) => {
            var $newImage = $(newImage);
            $newImage.after($newWishlistIcon.clone(true));
        });
    }

    // Toggle between contact sales and add to cart button
    if (response.product.custom.contactSales === true) {
        $('.add-to-cart-container .add-to-cart').each(function() {
            $(this).hide()
        });
        $('.add-to-cart-container .contact-sales').each(function () {
            $(this).removeClass('d-none');
        });
    } else {
        $('.add-to-cart-container .add-to-cart').each(function () {
            $(this).show();
        });
        $('.add-to-cart-container .contact-sales').each(function () {
            $(this).addClass('d-none');
        });
    }
}

/**
 * Changed from AB base to never disable the minus button.
 * The minus button is used to remove the item form the cart.
 */
function updateQuantityStepperDisabledStates($stepper) {
    var max = parseInt($stepper.attr('data-max'));
    var $input = $stepper.find('input');
    var $plusButton = $stepper.find('[data-action="increase"]');
    var value = !isNaN(parseInt($input.prop('data-qty'))) ? parseInt($input.prop('data-qty')) : parseInt($input.attr('data-qty'));

    if (value >= max) {
        $plusButton.addClass('disabled');
    } else {
        $plusButton.removeClass('disabled');
    }
}

/**
 * Changed from AB base to remove the item from the cart using the quantity stepper.
 */
function bindQuantityStepperButtons($stepper) {
    var scope = this;
    var $select = $stepper.prev('select');
    var min = parseInt($stepper.data('min'));
    var max = parseInt($stepper.data('max'));

    $stepper.find('button').off('click').click(event => {
        var $button = $(event.target);
        var action = $button.data('action');
        var previousValue = parseInt($stepper.find('input').val());
        var newValue = previousValue;

        if (action === 'increase' && (previousValue + 1 <= max)) {
            newValue++;
        }
        if (action === 'decrease' && (previousValue - 1 >= min)) {
            newValue--;
        }
        if (newValue !== previousValue) {
            $select.find('option[value="' + newValue + '"]').prop('selected', true).change();
            $stepper.find('input').prop('value', newValue).prop('data-qty', newValue);
            scope.updateQuantityStepperDisabledStates($stepper);
            $('body').trigger('quantityStepper:change', $stepper);
        } else if (newValue === previousValue && previousValue === 1 && action === 'decrease') {
            $stepper.closest('.line-item').find('.remove-product').click();
        }
    });
}

// set the original addtocart function so that it can be called from the custom addtocart function
var originalAddToCart = base.addToCart;

/**
 * Add the product to the cart via ajax
 */
function addToCart() {
    originalAddToCart.call(this);
    $('.btn-buy-again').on('click', function(e) {
        var $this = $(this);
        e.preventDefault();
        var $el = $this.closest('.line-item');
        // if the button can't be found try looking in the hsitory tile row or product summary block
        if ($el.length === 0) {
            $el = $this.closest('.history-tile-row, .product-summary-block').find('.line-item');
        }
        handleAddToCart.call(this, $el);
    });

    $('.btn-buy-all-again').on('click', function(e) {
        var $this = $(this);
        var $historyTileRow = $this.closest('.history-tile-row, .product-summary-block').find('.line-item');
        $.each($historyTileRow, function(i, el) {
            var $el = $(el);
            handleAddToCart.call(this, $el, function(data) {
                if (i === $historyTileRow.length - 1) {
                    $('body').trigger('product:afterAddToCart', data);
                    $.spinner().stop();
                }
            });
        });
    });
}

function handleAddToCart(el, callback) {
    $('body').trigger('product:beforeAddToCart', this);

    var $el = $(el);
    var item = $el.data('item');
    var pid = item.id;
    var isCustomizerProduct = item.isCustomizerProduct;
    var customizerRecipeJSON = item.hasOwnProperty('customizerRecipeJSON') ? JSON.parse(item.customizerRecipeJSON) : null;
    var itemqty = $el.data('qty');
    var url = $el.data('add-to-cart-url');
    var addCustomToCartUrl = $el.data('add-custom-to-cart-url');
    console.log(item);

    var form = {
        pid: pid,
        quantity: itemqty
    };

    $.ajax({
        url: (isCustomizerProduct === true) ? addCustomToCartUrl : url,
        method: 'POST',
        data: (isCustomizerProduct === true) ? customizerRecipeJSON : form,
        success: function(data) {
            base.methods.handlePostCartAdd(data);
            base.methods.miniCartReportingUrl(data.reportingURL);
            if (callback) {
                callback(data);
            } else {
                $('body').trigger('product:afterAddToCart', data);
                $.spinner().stop();
            }
        },
        error: function(error) {
            console.error(error);
            $.spinner().stop();
        }
    });
}

base.handleSpinner = handleSpinner;
base.enhancedAddToCartMessaging = enhancedAddToCartMessaging;
base.disabledAddToCartScrollToTop = disabledAddToCartScrollToTop;
base.methods.processSwatchValues = processSwatchValues;
base.methods.processNonSwatchValues = processNonSwatchValues;
base.methods.handleVariantResponse = handleVariantResponse;
base.nonColorAttribute = nonColorAttribute;
base.addBonusProductsToCart = addBonusProductsToCart;
base.sizeChart = sizeChart;
base.methods.updateQuantityStepperDisabledStates = updateQuantityStepperDisabledStates;
base.methods.bindQuantityStepperButtons = bindQuantityStepperButtons;
base.addToCart = addToCart;

module.exports = base;
